<template>
  <div class="page-item-wrap">
    <main class="sub __login">
      <div class="inner __login-wrap">
        <div class="tit req-type">
          <h1>아이디 찾기</h1>
          <p class="stit">계정의 아이디를 잊으셨나요?</p>
        </div>
        <div class="sub-cont result">
          <div class="result-box">
            <p class="result-txt">
              <!--회원님의 아이디는 <strong>calebk**</strong> 입니다.-->
              회원님의 아이디는
              <strong>{{ foundId }}</strong>
              입니다.
            </p>
            <p class="t1">
              개인정보 보호를 위해 아이디의 끝자리는 *로 표시하였습니다.
            </p>
          </div>
          <div class="login-btn">
            <router-link to="/login">로그인</router-link>
            <router-link to="/find-pw">비밀번호 찾기</router-link>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import { getFindIdWithSms, getFindIdWithEmail } from 'Api/modules'
export default {
  props: {
    foundId: {
      type: String,
      default: '',
    },
  },
}
</script>

<style></style>
