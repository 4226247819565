<template>
  <find-id v-if="view === 'findId'" @findIdResult="findIdResult"></find-id>
  <find-id-result
    :foundId="foundId"
    v-else-if="view === 'find-id-result'"
  ></find-id-result>
</template>

<script>
import FindId from 'Components/account/content/FindId'
import FindIdResult from 'Components/account/content/FindIdResult'

export default {
  components: {
    FindId,
    FindIdResult,
  },
  data() {
    return {
      view: 'findId',
      foundId: '',
    }
  },
  methods: {
    findIdResult(foundId) {
      this.view = 'find-id-result'
      this.foundId = foundId
    },
  },
}
</script>

<style></style>
