<template>
  <div class="page-item-wrap">
    <main class="sub __login">
      <div class="inner __login-wrap">
        <div class="tit req-type m-show">
          <h1>아이디 찾기</h1>
          <p class="stit">계정의 아이디를 잊으셨나요?</p>
        </div>
        <div class="sub-cont">
          <div class="link-tab">
            <a
              href="javascript:void(0)"
              class="link"
              :class="{ active: !isEmail }"
              @click="setTab('PHONE')"
            >
              휴대폰 번호로 찾기
            </a>
            <a
              href="javascript:void(0)"
              class="link"
              :class="{ active: isEmail }"
              @click="setTab('EMAIL')"
            >
              이메일 주소로 찾기
            </a>
          </div>
          <div class="login-form-box">
            <p class="t1">
              회원정보에 등록된 정보로 아이디를 찾을 수 있습니다.
            </p>
            <input
              type="text"
              v-model="userNm"
              class="text"
              placeholder="이름"
              ref="userNm"
            />
            <check-auth-num-form
              :isEmail="isEmail"
              :userNm="userNm"
              @submit="submit"
            ></check-auth-num-form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import CheckAuthNumForm from 'Components/account/partials/CheckAuthNumForm'
import { getFindIdWithSms, getFindIdWithEmail } from 'Api/modules'

export default {
  components: {
    CheckAuthNumForm,
  },
  data() {
    return {
      isEmail: false,
      userNm: '',
    }
  },
  methods: {
    setTab(name) {
      if (name === 'PHONE') this.isEmail = false
      else this.isEmail = true
      // 휴대폰 번호로 찾기/이메일 주소 찾기 탭 누를때마다 이름 값 초기화
      this.userNm = ''
    },
    getMask(input, index, char) {
      return input.substr(0, index) + char + input.substr(index + char.length)
    },
    async submit({ certNo, certTargetValue }) {
      let res
      try {
        if (this.isEmail) {
          res = await getFindIdWithEmail({
            userNm: this.userNm,
            email: certTargetValue,
            certNo,
          })
        } else {
          res = await getFindIdWithSms({
            userNm: this.userNm,
            phone: certTargetValue,
            certNo,
          })
        }
        // 확인창 띄울필요가 있다.
        //if (res) this.$router.push('/find-id/result/' + res)
        // 마지막문자 * 처리
        res = this.getMask(res, res.length - 1, '*')
        res = res.replace()
        if (res) this.$emit('findIdResult', res)
      } catch (e) {
        console.error(e)
      }
    },
  },
  created() {
    this.$nextTick(() => {
      this.$refs['userNm'].focus()
    })
  },
}
</script>

<style></style>
